import React from 'react'


class Button extends React.Component {
    componentDidMount() {
        this.stripe = window.Stripe(
            'pk_test_15qq8FRI7GA9zGTDi7PulQuF00fbAevSJ7',
            {
                betas: ['checkout_beta_4']
            }
        );
    }
    render() {
        const BuyButtonWrapper = {
            textAlign: 'center',
        };
        const BuyButton = {
            textAlign: 'center',
            background: 'transparent',
            fontFamily: 'nunito',
            borderWidth: '1px',
            borderColor: 'black',
            padding: '5px 7px'
        };

        return (
            <form className='center mv2'
                style={BuyButtonWrapper}
                onSubmit={event => {
                    console.log('hello')
                    event.preventDefault()
                    this.stripe
                        .redirectToCheckout({
                            items: [
                                // Replace with the ID of your SKU
                                { sku: 'sku_123', quantity: 1 }
                            ],
                            successUrl: 'http://http://localhost:8000/success',
                            cancelUrl: 'http://http://localhost:8000/canceled',
                        })
                        .then(function (result) {
                            if (result.error) {
                                var displayError = document.getElementById('error-message');
                                displayError.textContent = result.error.message;
                            }
                        });
                }}>
                <button type="submit" style={BuyButton} > Buy Print</button>
            </form>
        )
    }
}

export default Button
